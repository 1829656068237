<template>
    <section class="project-text-slider">
        <div class="swiper-container gallery-top">
            <div class="swiper-wrapper">
                <div v-for="(item,index) in images" :key="index" class="swiper-slide">
<!--                    <picture>-->
<!--                        <source-->
<!--                            media="(max-width:767px)"-->
<!--                            :srcset="item.mobile"-->
<!--                        />-->
<!--                        <source-->
<!--                            media="(max-width:1023px)"-->
<!--                            :srcset="item.tablet"-->
<!--                        />-->
<!--                        <img-->
<!--                            :src="item.desktop"-->
<!--                        />-->
<!--                    </picture>-->
                    <ImageScale :id="'environment'+randomIntFromInterval(1,3000)" :item="{image:item.desktop}"></ImageScale>
                </div>
            </div>
        </div>
        <div class="swiper-container gallery-thumbs">
            <div class="swiper-wrapper">
                <div v-for="(item,index) in names" :key="index" class="swiper-slide">
                    <span class="title luxury">{{ item }}</span>
                </div>
            </div>
        </div>
        <WalkingParagraph :walking-paragraph="names"/>
    </section>
</template>

<script setup>
import Swiper, {FreeMode, Thumbs, Controller, Autoplay} from 'swiper';
import gsap from "gsap";
import ImageScale from "../../Popups/ImageScale.vue";

Swiper.use([FreeMode, Thumbs, Controller, Autoplay]);
import WalkingParagraph from '../ProjectSlider/parts/WalkingParagraph.vue'
import {computed, onMounted, provide, ref} from "vue";

const props = defineProps({
    contentData: {
        type: Object,
    },
});

const emit = defineEmits(['startTextSwiper'])

function swapElement(array, indexA, indexB) {
    let tmp = array[indexA];
    array[indexA] = array[indexB];
    array[indexB] = tmp;
}
const randomIntFromInterval = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

let images = computed(() => props.contentData.data?.list?.map(item => ({
    mobile: item?.images?.[0]?.devices?.mobile,
    desktop: item?.images?.[0]?.devices?.desktop,
    tablet: item?.images?.[0]?.devices?.tablet,
})))
let names = computed(() => props.contentData.data?.list?.map(item => item.title) || [])

let direction = ref(1)
let eventWatcher = ref(false)

provide('textSwiper', {
    direction,
    eventWatcher
})
const setSwiper = () => {
    const galleryTop = new Swiper('.gallery-top', {
        loopedSlides: names.length,
        loop: true,
        touchRatio: 1.3,
        freeMode: {
            enabled: true,
            sticky: false,
        },
        grabCursor: true,
        centeredSlides: true,
        spaceBetween: 21,
        speed: 1200,
        slidesPerView: 1.3,
        breakpoints: {
            767: {
                slidesPerView: 'auto',
            }
        }
    });
    galleryTop.on('touchStart', () => {
        // gsap.to('.gallery-top .swiper-slide', {
        //     scale: .9,
        //     clipPath: 'inset(20px 20px 20px 30px)',
        //     duration: .6,
        //     ease: 'power1.out'
        // })

    });
    galleryTop.on('touchEnd', () => {
        // gsap.to('.gallery-top .swiper-slide', {
        //     scale: 1,
        //     duration: .6,
        //     clipPath: 'inset(0px 0px 0px 0px)',
        //     delay: .2,
        //     ease: 'power1.out'
        // })
    });
    galleryTop.on('slideChangeTransitionStart', (e) => {
        direction.value = e.touches.diff < 0 ? -1 : 1
        eventWatcher.value = !eventWatcher.value
    });
    const galleryThumbs = new Swiper('.gallery-thumbs', {
        loopedSlides: names.length,
        spaceBetween: 55,
        loop: true,
        freeMode: true,
        grabCursor: true,
        centeredSlides: true,
        slideToClickedSlide: true,
        speed: 1200,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        slidesPerView: 1.3,
        breakpoints: {
            767: {
                slidesPerView: 'auto',
            }
        }
    });

    galleryTop.controller.control = galleryThumbs;
    galleryThumbs.controller.control = galleryTop;
}

onMounted(() => {
    setSwiper()
})
</script>

<style lang="scss" scoped>
.project-text-slider {
    $ease: cubic-bezier(.43, 0, .29, .99);
    $ease-in: cubic-bezier(0, .19, .01, .99);
    padding-top: 225px;
    @media only screen and (max-width: 1439px) and (min-width: 1024px) {
        padding-top: 175px;
    }
    @media only screen and (max-width: 1023px) {
        padding-top: 0;
    }

    .swiper-slide {
        font-style: normal;
        font-weight: 400;
        font-size: 34px;
        text-transform: uppercase;
        color: $primaryNavy;
        text-align: center;
        width: fit-content;
    }

    .gallery-thumbs {
        margin-top: 55px;
        @media only screen and (max-width: 1023px) {
            margin-top: 34px;
        }
        .swiper-wrapper {
            transition-timing-function: $ease;
        }

        .swiper-slide {
            opacity: .2;
            transition: opacity 1s $ease;
            user-select: none;

            &.swiper-slide-active {
                opacity: 1;
            }

            .title {
                font-weight: 400;
                font-size: 34px;
                text-transform: uppercase;
                @media (max-width: 767px) {
                    font-size: 21px;
                }
            }
        }
    }

    .gallery-top {

        .swiper-wrapper {
            //transition-timing-function: $ease-in;
            .swiper-slide {
                height: 300px;
                width: 600px;
                user-select: none;
                @media only screen and (max-width: 1650px) and (min-width: 1440px) {
                    width: 500px;
                }
                @media only screen and (max-width: 1439px) and (min-width: 1024px) {
                    width: 400px;
                }
                @media only screen and (max-width: 1023px) and (min-width: 768px) {
                    width: 350px;
                }
                @media (max-width: 767px) {
                    height: 147px;
                    width: unset;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;

                }
            }
        }
    }


}
</style>

<style lang="scss">
body {
    &.is-dark {
        .project-text-slider {
            .swiper-slide {
                color: white;
            }
        }
    }
}

</style>
